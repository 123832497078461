/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import PageContainer from "../components/PageContainer";
import SectionTitle from "../components/SectionTitle";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <PageContainer>
      <SectionTitle title={"OOPS!"}>OOPS!</SectionTitle>
      <h2 sx={{ textAlign: "center" }}>404 - PAGE NOT FOUND</h2>
      <p sx={{ textAlign: "center" }}>
        The page you are looking for does not exist or is temporarily
        unavailable
      </p>
      <Link
        sx={{
          textAlign: "center",
          color: "black",
          display: "block",
          m: [5],
          textDecoration: "underline",
        }}
        href="/"
      >
        Back to homepage
      </Link>
    </PageContainer>
  </Layout>
);

export default NotFoundPage;
